import React from "react";

export default function ChromeIcon() {
    return (
        <svg width='1em' height='1em' style={{ display: 'unset', marginRight: 8 }} viewBox="0 0 128 128">
            <g id="_x34__stroke">
                <g id="Google_Chrome">
                    <rect clip-rule="evenodd" fill="none" fill-rule="evenodd" height="128" width="128" />
                    <g id="Google_Chrome_1_">
                        <path clip-rule="evenodd" d="M15.454,22.297c29.752-34.626,84.917-27.791,105.54,12.656     c-14.517,0.005-37.25-0.004-49.562,0c-8.93,0.003-14.695-0.2-20.939,3.087c-7.34,3.864-12.879,11.026-14.812,19.439     L15.454,22.297z" fill="currentColor" fill-rule="evenodd" />
                        <path clip-rule="evenodd" d="M42.708,63.998c0,11.735,9.542,21.283,21.271,21.283     c11.728,0,21.27-9.547,21.27-21.283c0-11.735-9.542-21.283-21.27-21.283C52.25,42.715,42.708,52.263,42.708,63.998z" fill="currentColor" fill-rule="evenodd" />
                        <path clip-rule="evenodd" d="M72.234,91.855c-11.939,3.548-25.91-0.387-33.563-13.597     C32.829,68.175,17.394,41.286,10.379,29.06c-24.57,37.659-3.394,88.978,41.212,97.737L72.234,91.855z" fill="currentColor" fill-rule="evenodd" />
                        <path clip-rule="evenodd" d="M83.737,42.715c9.944,9.248,12.11,24.224,5.374,35.836     c-5.075,8.749-21.271,36.085-29.121,49.322c45.958,2.833,79.461-42.209,64.328-85.158H83.737z" fill="currentColor" fill-rule="evenodd" />
                    </g>
                </g>
            </g>
        </svg>
    );
}
