import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import { Container, Row, Col } from "./ui/grid"
import ShapeDown from "./ui/shape-down"
import Button from "./ui/button"
import TextGradient from "./ui/text-gradient"

import poster from "../images/composer-video-poster.png"
import video from "../images/composer-demo.mp4"
import ChromeIcon from "./ui/chrome"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return (
        <h1 className="text-white pr-12 lg:pr-20 xl:text-6xl">{children}</h1>
      )
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <p className="mt-4 text-lg text-white font-medium md:mt-6">
          {children}
        </p>
      )
    },
  },
}

const HeroTestComposer = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageComposerOnboarding {
          heroContent {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          buttonPrimary {
            title
            url
          }
        }
      }
    `
  )

  const content = data?.heroContent || "";
  const btnPrimary = data?.buttonPrimary || "";

  const chromeBtnTitle = (
    <>
      <ChromeIcon />
      {btnPrimary?.title}
    </>
  )

  return (
    <ShapeDown className="pt-12 lg:pt-40">
      <section className="mb-12 overflow-hidden lg:mb-24">
        <Container>
          <Row>
            <Col size="w-full lg:w-1/2">
              {content && renderRichText(content, options)}

              <div className="mt-8 space-y-4 md:space-y-0 md:space-x-4">
                <Button
                  label={chromeBtnTitle}
                  to={btnPrimary?.url}
                  color="pink"
                  className="block md:inline-block"
                />
              </div>
            </Col>

            <Col size="w-full lg:w-1/2" className="order-last">
              <div className="mt-8 lg:-mr-12 lg:mt-0">
                <div className="relative pb-[64%]">
                  <video
                    width="100%"
                    height="300px"
                    preload="auto"
                    className="absolute w-full h-full top-0 bottom-0 left-0 right-0"
                    poster={poster}
                    loop
                    autoPlay
                    muted>
                    <source src={video} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </ShapeDown>
  )
}

export default HeroTestComposer
