import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { gsap } from "../plugins/gsap"
import { ScrollTrigger } from "../plugins/gsap/ScrollTrigger"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Feature from "../components/ui/feature"
import HeroTestComposer from "../components/hero-test-composer"
import Customers from "../components/customers"
import ShapeUp from "../components/ui/shape-up"
import Button from "../components/ui/button"
import ChromeIcon from "../components/ui/chrome"
import { getYamlContent } from "../components/editableYaml"

gsap.registerPlugin(ScrollTrigger)

const TestComposer = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageComposerOnboarding {
          title
          description
          usersLogosTitle
          buttonPrimary {
            title
            url
          }
          featuredImage {
            gatsbyImageData
            resize(width: 512, quality: 70) {
              src
            }
          }
          features {
            title
            id
            content {
              raw
            }
            image {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 70
                placeholder: BLURRED
              )
            }
            editableTextYaml {
              raw
            }
          }
        }
      }
    `
  );

  const title = data?.title || "";
  const description = data?.description || "";
  const thumbnail = data?.featuredImage?.resize?.src || "";
  const features = data?.features || [];
  const usersLogosTitle = data?.usersLogosTitle || "";
  const btnPrimary = data?.buttonPrimary || "";

  const sectionRefs = useRef([]);
  sectionRefs.current = [];
  useEffect(() => {
    sectionRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
        },
        {
          duration: 1,
          autoAlpha: 1,
          ease: "power4.out",
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      )
    })
  }, [])

  const addToRef = el => {
    if (el && !sectionRefs.current.includes(el)) {
      sectionRefs.current.push(el)
    }
  }

  const chromeBtnTitle = (
    <>
      <ChromeIcon />
      {btnPrimary?.title}
    </>
  )

  return (
    <Layout
      location={location}
      hero={<HeroTestComposer />}
    >
      <Seo
        title={title}
        description={description}
        image={thumbnail}
        url={location.href}
      />
      
      {features &&
        features.map((item, index) => (
            <Feature
              elRef={addToRef}
              id={item?.id}
              key={item?.id}
              content={item?.content}
              img={item?.image?.gatsbyImageData}
              imgAlt={item?.title}
              imgSide={index % 2 === 0 ? "top-right" : "top-left"}
              yamlContent={item?.editableTextYaml && getYamlContent(item?.editableTextYaml)}
              alignY="center"
              options="alt"
              className="py-12 lg:py-24"
            />
          ))}

      <ShapeUp>
        <p className="mt-20 mb-10 text-lg text-center text-gray-light font-normal opacity-75">
          {usersLogosTitle}
        </p>

        <Customers all/>

        <div className="flex justify-center mt-20">
          <Button
            label={chromeBtnTitle}
            to={btnPrimary?.url}
            color="pink"
            className="block md:inline-block"
          />
        </div>
      </ShapeUp>

    </Layout>
  )
}

export default TestComposer
